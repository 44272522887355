import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"

const CustomerAvils = () => {
  const customerAnvils = []
  const data = useStaticQuery(graphql`
    query CustomerPages {
      allMarkdownRemark(
        sort: { fields: [frontmatter___anvilDetails], order: ASC }
        limit: 1000
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              anvilDetails
            }
          }
        }
      }
    }
  `)

  data.allMarkdownRemark.edges.forEach(edge => {
    if (edge.node.frontmatter.anvilDetails !== null) {
      customerAnvils.push(
        <div className="customer-anvil" key={edge.node.fields.slug}>
          <h5>{edge.node.frontmatter.anvilDetails}</h5>
          <Link to={edge.node.fields.slug.replace(/\/$/, "")}>
            {edge.node.frontmatter.title}
          </Link>
        </div>
      )
    }
  })

  return (
    <Layout>
      <article>
        <h1>Refflinghaus Anvils At Work</h1>
        {customerAnvils}
      </article>
    </Layout>
  )
}

export default CustomerAvils
